import React from "react";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import NumberFormatter from "../../../../../components/formatters/NumberFormatter";
import DateFormatter from "../../../../../components/formatters/DateFormatter";
import StatusPill from "../../../../../components/StatusPill";
import usePermissions from "../../../../../hooks/usePermissions";
import DeviceIcon from "../../../../../components/DeviceIcon";
import Device3PhaseData from "./components/Device3PhaseData";
import DeviceCardRow from "./components/DeviceCardRow";

export function InstallationInverterCard({ inverter, onClickInverterState, onClickInverterEnergyAlgorithm, onClickAddDevice, disabled }) {
    const permissions = usePermissions();

    return (
        <div className="card mb-3">
            <div className="card-body">
                <div className="d-flex flex-row">
                    <div className="flex-grow-1">
                        <h5 className="card-title">
                            { inverter.manufacturer }{" "}
                            <br className="d-md-none"/>
                            { inverter.model }
                            <div className="text-muted mt-1" style={{ fontSize: "0.85rem" }}>
                                { inverter.serialNumber }
                            </div>
                        </h5>
                    </div>
                    <div className="ml-3">
                        <DeviceIcon deviceType="inverter"/>
                    </div>
                </div>
                <DeviceCardRow title="Status">
                    { inverter.status?.name ?? "Unknown" }
                </DeviceCardRow>
                { (permissions.manageInstallations && inverter.version) ? (
                    <DeviceCardRow title="Versie">
                        <div>
                            { inverter.version }
                        </div>
                    </DeviceCardRow>
                ) : null }
                <DeviceCardRow title="Sturing">
                    <StatusPill value={ inverter.preferredEnergyHandlingMode }/>
                    { onClickInverterState && (
                        <OverlayTrigger overlay={
                            <Tooltip id={`inverter-preferredstate`}>
                                Wijzig de sturing van deze omvormer
                            </Tooltip>
                        }>
                            <Button variant="link" size="sm" className="py-0" onClick={ () => onClickInverterState(inverter) } disabled={ disabled }>
                                <i className="fa-solid fa-pen"/>
                            </Button>
                        </OverlayTrigger>
                    )}
                </DeviceCardRow>
                <DeviceCardRow title="Algoritme">
                    { inverter.energyAlgorithm?.name ?? "Niet ingesteld" }
                    { onClickInverterEnergyAlgorithm && (
                        <OverlayTrigger overlay={
                            <Tooltip id={`inverter-preferredstate`}>
                                Wijzig het algoritme van deze omvormer
                            </Tooltip>
                        }>
                            <Button variant="link" size="sm" className="py-0" onClick={ () => onClickInverterEnergyAlgorithm(inverter) } disabled={ disabled }>
                                <i className="fa-solid fa-pen"/>
                            </Button>
                        </OverlayTrigger>
                    )}
                </DeviceCardRow>
                <DeviceCardRow title="AC Power">
                    { inverter.powerAc !== null ? (
                        <React.Fragment>
                            <NumberFormatter
                                number={ inverter.powerAc }
                                maximumFractionDigits={ 1 }
                            /> W
                        </React.Fragment>
                    ) : "Unknown" }
                </DeviceCardRow>
                { (inverter.voltageAcL1 || inverter.voltageAcL2 || inverter.voltageAcL3) ? (
                    <DeviceCardRow title="AC Spanning">
                        <Device3PhaseData
                            l1={ inverter.voltageAcL1 > 50 ? inverter.voltageAcL1 : null }
                            l2={ inverter.voltageAcL2 > 50 ? inverter.voltageAcL2 : null }
                            l3={ inverter.voltageAcL3 > 50 ? inverter.voltageAcL3 : null }
                            unit="V"
                        />
                    </DeviceCardRow>
                ) : null }
                <DeviceCardRow title="DC Power">
                    { inverter.powerDc !== null ? (
                        <React.Fragment>
                            <NumberFormatter
                                number={ inverter.powerDc }
                                maximumFractionDigits={ 2 }
                            /> W
                        </React.Fragment>
                    ) : "Unknown" }
                </DeviceCardRow>
                <DeviceCardRow title="Laatst gezien">
                    <DateFormatter date={ inverter.lastSeenDate }/>
                </DeviceCardRow>
                { onClickAddDevice && inverter.installationId === null && (
                    <div className="d-flex justify-content-end mt-2">
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={ () => onClickAddDevice("inverter", inverter.id) }
                            disabled={ disabled }
                        >
                            <i className="fa-solid fa-plus mr-2"/>
                            Omvormer toevoegen
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

function InstallationInverterCards({ inverters, onClickInverterState, onClickAddDevice, disabled }) {
    return inverters.map((inverter) => (
        <InstallationInverterCard
            key={inverter.id}
            inverter={inverter}
            onClickInverterState={onClickInverterState}
            onClickAddDevice={onClickAddDevice}
            disabled={disabled}
        />
    ));
}

export default React.memo(InstallationInverterCards);
